import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
window.bootstrap = bootstrap;

import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import Choices from "choices.js";

import "choices.js/public/assets/styles/choices.min.css";

import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// equal height column
function equalizeColumns() {
    if (window.innerWidth >= 992) {
        let currentElement = document.querySelector(".dynamic-column");
        let nextElement = currentElement ? currentElement.nextElementSibling : null;

        if (currentElement && nextElement) {
            nextElement.style.height = currentElement.offsetHeight + "px";
            nextElement.style.width = window.innerWidth - currentElement.offsetWidth + "px";
        }
    }
}

equalizeColumns();

window.addEventListener("resize", equalizeColumns);

// light and dark mode logo animation gif
document.addEventListener("DOMContentLoaded", function () {
    if (window.innerWidth > 992) {
        var gifLogo = document.getElementById("gifImage");
        var gifLogoDark = document.getElementById("gifImageDark");
        var staticLogo = document.getElementById("staticImage");
        var staticLogoDark = document.getElementById("staticImageDark");

        if (staticLogo && staticLogoDark) {
            staticLogo.classList.add("hidden");
            staticLogoDark.classList.add("hidden");
        }

        setTimeout(function () {
            if (gifLogo) gifLogo.classList.add("hidden");
            if (gifLogoDark) gifLogoDark.classList.add("hidden");
            if (staticLogo) staticLogo.classList.add("show");
            if (staticLogoDark) staticLogoDark.classList.add("show");
        }, 3500);
    }
});

// audio and swiper
document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".o-series-slider").forEach(function (container) {
        var swiperElement = container.querySelector(".swiper");
        var nextButton = container.querySelector(".swiper-button-next");
        var prevButton = container.querySelector(".swiper-button-prev");

        if (swiperElement && nextButton && prevButton) {
            var swiper = new Swiper(swiperElement, {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                },
            });
        }
    });

    const audioIcons = document.querySelectorAll(".icon-audio");

    if (audioIcons.length > 0) {
        audioIcons.forEach((audioIcon) => {
            let audioSrc = audioIcon.getAttribute("data-audio");
            let audio = new Audio(audioSrc);
            let isPlaying = false;

            audioIcon.addEventListener("click", function () {
                if (!isPlaying) {
                    audio.play();
                    audioIcon.classList.add("active");
                    isPlaying = true;
                } else {
                    audio.pause();
                    audioIcon.classList.remove("active");
                    isPlaying = false;
                }

                audio.addEventListener("ended", function () {
                    audioIcon.classList.remove("active");
                    isPlaying = false;
                });
            });
        });
    }
});

var searchElement = document.getElementById("search-js-choice");
if (searchElement) {
    var search = new Choices(searchElement, {
        allowHTML: true,
        delimiter: ",",
        editItems: true,
        placeholderValue: myTranslations.click_to_search ? myTranslations.click_to_search : "Click to search",
        removeItemButton: true,
    });
}

setTimeout(function () {
    var parentDiv = document.querySelector(".language-dropdown-container");
    if (parentDiv) {
        parentDiv.classList.add("show");
        var languageElement = parentDiv.querySelector('[name="language-js-choice"]');
        if (languageElement) {
            var language = new Choices(languageElement, {
                allowHTML: true,
                delimiter: ",",
                editItems: true,
                removeItemButton: false,
                searchEnabled: false,
            });
        }
    }
}, 1000);

// Filters on Video and Audio layout - changing from tags on desktop to select dropdown on mobile
window.addEventListener("resize", function () {
    var languageContainers = document.querySelectorAll(".o-video-layout__headline-and-filter");
    languageContainers.forEach(function (container) {
        if (window.innerWidth <= 991) {
            showLanguageSelect(container);
        } else {
            showLanguageTags(container);
        }
    });
});

function showLanguageSelect(container) {
    var languageTagsContainer = container.querySelector(".m-tags__tags-wrapper");
    if (!languageTagsContainer) {
        return; // Exit the function if container doesn't exist
    }

    languageTagsContainer.style.display = "none";

    var languageSelectContainer = container.querySelector(".m-tags__select-wrapper");
    if (languageSelectContainer) {
        var existingSelect = languageSelectContainer.querySelector("select");
        if (!existingSelect) {
            var select = document.createElement("select");
            select.className = "m-tags__child";

            var languageButtons = container.querySelectorAll(".m-tags__tags-wrapper .m-tags__child");
            languageButtons.forEach(function (button) {
                var option = document.createElement("option");
                option.value = button.textContent.toLowerCase();
                option.text = button.textContent;
                select.appendChild(option);
            });
            languageSelectContainer.appendChild(select);
        }
    }
}

function showLanguageTags(container) {
    var languageTagsContainer = container.querySelector(".m-tags__tags-wrapper");
    if (languageTagsContainer) {
        languageTagsContainer.style.display = "flex";
    }

    var languageSelectContainer = container.querySelector(".m-tags__select-wrapper");
    if (languageSelectContainer) {
        languageSelectContainer.innerHTML = ""; // Clear dropdown select
    }
}

// Initial check on page load
var languageContainers = document.querySelectorAll(".o-video-layout__headline-and-filter");
languageContainers.forEach(function (container) {
    if (window.innerWidth <= 991) {
        showLanguageSelect(container);
    } else {
        showLanguageTags(container);
    }
});



// SCRIPTS FROM FOOTER
document.querySelectorAll("[data-navigation]").forEach((el) => {
    if (window.location.href.includes("/category/" + el.dataset.navigation)) {
        el.classList.add("is-active");
    }
});

// Add class is-active for Video / Audio filters
document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(".m-tags__child").forEach((el) => {
        const urlParts = window.location.href.split("/");
        const categoryIndex = urlParts.indexOf("category");

        if (categoryIndex !== -1) {
            if (urlParts[categoryIndex + 2] === el.dataset.category) {
                el.classList.add("is-active");
            }
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    function set_dark_mode_cookie() {
        document.cookie = "yonkovNightMode=1; path=/";
    }

    function remove_dark_mode_cookie() {
        document.cookie =
            "yonkovNightMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    function toggle_dark_mode() {
        const html = document.documentElement;
        const currentTheme = html.getAttribute("data-bs-theme");
        const darkModeImages = document.querySelectorAll(".darkModeImage");
        const lightModeImages = document.querySelectorAll(".lightModeImage");
        const newTheme = currentTheme === "dark" ? "light" : "dark";
        html.setAttribute("data-bs-theme", newTheme);

        if (newTheme === "dark") {
            set_dark_mode_cookie();
            darkModeImages.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-flex");
            });
            lightModeImages.forEach(function (element) {
                element.classList.remove("d-flex");
                element.classList.add("d-none");
            });
        } else {
            remove_dark_mode_cookie();
            lightModeImages.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-flex");
            });
            darkModeImages.forEach(function (element) {
                element.classList.remove("d-flex");
                element.classList.add("d-none");
            });
        }

        // Update all text-switch elements
        document
            .querySelectorAll(".text-switch")
            .forEach(function (textSwitchSpan) {
                if (newTheme === "dark") {
                    textSwitchSpan.textContent = myTranslations.switch_to_light_mode ? myTranslations.switch_to_light_mode : "Switch to light mode";
                } else {
                    textSwitchSpan.textContent = myTranslations.switch_to_dark_mode ? myTranslations.switch_to_dark_mode : "Switch to dark mode";
                }
            });
    }

    function update_dark_mode() {
        var yonkov_night_mode = document.cookie.replace(
            /(?:(?:^|.*;\s*)yonkovNightMode\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
        );
        if (yonkov_night_mode === "1") {
            document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
                btn.classList.add("active");
            });
            document
                .querySelectorAll(".text-switch")
                .forEach(function (textSwitchSpan) {
                    textSwitchSpan.textContent = myTranslations.switch_to_light_mode ? myTranslations.switch_to_light_mode : "Switch to light mode";
                });
            document.documentElement.setAttribute("data-bs-theme", "dark");
            toggleImageVisibility(true);
        } else {
            document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
                btn.classList.remove("active");
            });
            document
                .querySelectorAll(".text-switch")
                .forEach(function (textSwitchSpan) {
                    textSwitchSpan.textContent = myTranslations.switch_to_dark_mode ? myTranslations.switch_to_dark_mode : "Switch to dark mode";
                });
            document.documentElement.removeAttribute("data-bs-theme");
            toggleImageVisibility(false);
        }
    }

    function toggleImageVisibility(darkMode) {
        var darkModeImages = document.querySelectorAll(".darkModeImage");
        var lightModeImages = document.querySelectorAll(".lightModeImage");

        if (darkMode) {
            darkModeImages.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-flex");
            });
            lightModeImages.forEach(function (element) {
                element.classList.remove("d-flex");
                element.classList.add("d-none");
            });
        } else {
            lightModeImages.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-flex");
            });
            darkModeImages.forEach(function (element) {
                element.classList.remove("d-flex");
                element.classList.add("d-none");
            });
        }
    }

    document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
        btn.addEventListener("click", function () {
            toggle_dark_mode();
        });
    });

    update_dark_mode();

    document.querySelectorAll('[name="language-js-choice"]').forEach((el) => {
        el.addEventListener("change", function (e) {
            window.location.href = el.value;
        });
    });
});



var swiperImages = new Swiper(".swiper-images", {
    loop: true,
    slidesPerView: 1,

    navigation: {
        nextEl: ".next-images",
        prevEl: ".prev-images",
    },
});



// Slider with Popup
document.addEventListener("DOMContentLoaded", function () {
    const galleries = document.querySelectorAll(".gallery-container"); // Select each gallery container

    galleries.forEach((gallery, galleryIndex) => {
        const bigSlider = gallery.querySelector("#big-slider ul"); // Select the big-slider within the gallery
        const thumbSlider = gallery.querySelector("#thumb-slider ul"); // Select the thumb-slider within the gallery
        const thumbSlides = gallery.querySelectorAll("#thumb-slider li");
        const slideCount = thumbSlides.length;
        const visibleThumbs = 4;
        let currentIndex = 0;

        if (bigSlider && thumbSlider && thumbSlides.length > 0) {
            const bigSliderLinks = gallery.querySelectorAll("#big-slider a.swipebox");

            bigSliderLinks.forEach((link, index) => {
                const img = link.querySelector('img');
                if (img) {
                    // Set the href attribute to the src of the image for each link
                    link.setAttribute("href", img.getAttribute("src"));
                }

                // Check if Fancybox is already bound by checking a custom data attribute
                if (!link.hasAttribute("data-fancybox-initialized")) {
                    // Group each gallery separately using a unique data-fancybox value
                    link.setAttribute("data-fancybox", `fancybox-slider-${galleryIndex}`);

                    // Get the photographer info from "productBlock__fotographer"
                    const photographerElement = link.closest('.productBlock').querySelector('.productBlock__fotographer');
                    const photographer = photographerElement ? photographerElement.textContent.trim() : "";

                    // Get the title
                    const title = link.getAttribute("title") || "";

                    // Build the caption based on whether title and photographer exist
                    let caption = "";

                    if (title) {
                        caption += `<div class="fancybox-title">${title}</div>`;
                    }

                    if (photographer) {
                        caption += `<div class="fancybox-description">${photographer}</div>`;
                    }

                    // Set data-caption only if there's content
                    if (caption) {
                        link.setAttribute("data-caption", caption);
                    }

                    // Mark the link as initialized for Fancybox
                    link.setAttribute("data-fancybox-initialized", "true");
                }
            });

            function updateSlider() {
                bigSlider.style.transform = `translateX(-${currentIndex * 100}%)`;

                thumbSlides.forEach((thumb, index) => {
                    thumb.classList.toggle("active", index === currentIndex);
                });

                const thumbWidth = thumbSlides[0].offsetWidth;
                const maxOffset = Math.max(
                    0,
                    thumbSlider.scrollWidth - thumbSlider.offsetWidth
                );
                const offset = Math.min(
                    (currentIndex - Math.floor(visibleThumbs / 2)) * thumbWidth,
                    maxOffset
                );

                thumbSlider.style.transform = `translateX(-${offset}px)`;

                const nextButton = gallery.querySelector(".next-button");
                if (nextButton) {
                    nextButton.disabled = currentIndex >= slideCount - 1;
                }

                const prevButton = gallery.querySelector(".prev-button");
                if (prevButton) {
                    prevButton.disabled = currentIndex <= 0;
                }
            }

            function showNextSlide() {
                if (currentIndex < slideCount - 1) {
                    currentIndex++;
                    updateSlider();
                }
            }

            function showPreviousSlide() {
                if (currentIndex > 0) {
                    currentIndex--;
                    updateSlider();
                }
            }

            // Thumbnail click
            thumbSlides.forEach((thumb, index) => {
                thumb.addEventListener("click", function (event) {
                    event.preventDefault();
                    currentIndex = index;
                    updateSlider();
                });
            });

            // Navigation buttons
            const nextButton = gallery.querySelector(".next-button");
            const prevButton = gallery.querySelector(".prev-button");

            if (nextButton) {
                nextButton.addEventListener("click", showNextSlide);
            }

            if (prevButton) {
                prevButton.addEventListener("click", showPreviousSlide);
            }

            updateSlider();

            // Initialize Fancybox for the gallery links, only if it's not already initialized
            if (typeof Fancybox !== 'undefined' && !Fancybox.getInstance()) {
                Fancybox.bind(`[data-fancybox="fancybox-slider-${galleryIndex}"]`, {
                    captions: function (fancybox, slide) {
                        return slide.$trigger.getAttribute("data-caption") || "";
                    },
                    infinite: false, // Prevents looping through galleries
                });
            } else if (typeof Fancybox === 'undefined') {
                console.error("Fancybox is not loaded.");
            }
        }
    });
});



//  FOOTER LINKS
document.addEventListener('DOMContentLoaded', function () {
    const menuLinks = document.querySelectorAll('.o-footer__mobile-links');

    function toggleAccordion(event) {
        event.preventDefault();

        const parent = this.closest('div');  // Find the closest div container

        if (parent) {
            const isOpen = parent.classList.contains('is-open');
            parent.classList.toggle('is-open', !isOpen);

            // Close all other sections
            menuLinks.forEach(function (otherLink) {
                const otherParent = otherLink.closest('div');
                if (otherParent && otherParent !== parent) {
                    otherParent.classList.remove('is-open');
                }
            });
        }
    }

    // Fix for iPhone - Ensure touch events do not conflict with clicks
    function handleTouch(event) {
        event.preventDefault();
        this.click();
    }

    // Apply accordion functionality on screens smaller than 992px
    function handleResize() {
        if (window.innerWidth < 992) {
            // Remove existing event listeners first (optional but useful if resizing)
            menuLinks.forEach(function (menuLink) {
                menuLink.removeEventListener('click', toggleAccordion);
                menuLink.removeEventListener('touchstart', handleTouch);
            });

            // Add event listeners for click and touchstart
            menuLinks.forEach(function (menuLink) {
                menuLink.addEventListener('click', toggleAccordion);
                menuLink.addEventListener('touchstart', handleTouch);  // Handle iPhone touch issue
            });
        } else {
            // Remove 'is-open' class and event listeners on larger screens
            menuLinks.forEach(function (menuLink) {
                const parent = menuLink.closest('div');
                if (parent) {
                    parent.classList.remove('is-open');
                }
                menuLink.removeEventListener('click', toggleAccordion);
                menuLink.removeEventListener('touchstart', handleTouch);
            });
        }
    }

    // Initial setup and handling resize event
    handleResize();
    window.addEventListener('resize', handleResize);
});



// Video Popup
document.addEventListener("DOMContentLoaded", function () {
    // Function to attach click event listeners to video articles
    function attachYouTubeModalListeners() {
        const youtubeArticles = document.querySelectorAll(".m-youtube-article, .m-row-video-article, .m-column-video-article, .m-popular-video");

        youtubeArticles.forEach(article => {
            // Skip articles that have the "audio" class
            if (article.classList.contains("audio")) {
                return;
            }

            article.addEventListener("click", function (event) {
                // Check if the clicked element is inside the tags section
                const tagsSection = article.querySelector(".m-category-article__content__bottom__tags");
                const gSpeech = article.querySelector(".gsp_circle_player");

                if ((tagsSection && tagsSection.contains(event.target)) || (gSpeech && gSpeech.contains(event.target))) {
                    // Do nothing if the user clicks inside the tags section or the gspeech section
                    return;
                }
                // Select the iframe within the article
                const iframe = article.querySelector("iframe");
                if (!iframe) return; // Ensure the article contains an iframe

                const videoSrc = iframe.src.replace("embed/", "embed/").concat("?autoplay=1&showinfo=0");

                // Create a modal element
                const modalHTML = `
                    <div class="modal videoModal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <iframe width="100%" height="100%" src="${videoSrc}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                `;

                // Check if modal already exists
                if (!document.getElementById("videoModal")) {
                    document.body.insertAdjacentHTML("beforeend", modalHTML);
                }

                // Show the modal
                const modalElement = document.getElementById("videoModal");
                const modal = new bootstrap.Modal(modalElement);
                modal.show();

                // Remove the modal from the DOM when hidden
                modalElement.addEventListener("hidden.bs.modal", function () {
                    modalElement.remove();
                });
            });
        });
    }

    // Call the function to attach listeners when the DOM is loaded
    attachYouTubeModalListeners();

    // Add event listeners after AJAX content is loaded
    document.addEventListener('ajaxContentLoaded', function () {
        attachYouTubeModalListeners();
    });
});



// Gallery Popup
document.addEventListener("DOMContentLoaded", function () {
    // Select all gallery blocks
    const galleries = document.querySelectorAll(".wp-block-gallery");

    galleries.forEach((gallery, index) => {
        // Select all figure elements in the current gallery block
        const galleryFigures = gallery.querySelectorAll("figure");

        galleryFigures.forEach((figure) => {
            const img = figure.querySelector("img");
            const figcaption = figure.querySelector("figcaption"); // Get the figcaption
            const captionText = figcaption ? figcaption.innerText : ''; // Get the text from figcaption if it exists

            // Check if img exists and has a valid src
            if (img && img.src) {
                // Check if the image is already wrapped in a link
                if (!img.parentElement.matches('a')) {
                    // Create a wrapper link element
                    const link = document.createElement("a");
                    link.href = img.src; // Link to the full-size image
                    link.setAttribute("data-fancybox", `fancybox-gallery-${index}`); // Assign a unique group name

                    // Set the caption for Fancybox
                    if (captionText) {
                        link.setAttribute("data-caption", captionText);
                    }

                    // Wrap the image with the link element
                    img.parentNode.insertBefore(link, img);
                    link.appendChild(img);
                }
            }
        });

        // Initialize Fancybox for the current gallery images
        if (typeof Fancybox !== 'undefined' && !Fancybox.getInstance()) {
            Fancybox.bind(`[data-fancybox="fancybox-gallery-${index}"]`, {
                mainClass: 'fancybox-gallery-images',
            });
        } else if (typeof Fancybox === 'undefined') {
            console.error("Fancybox is not loaded.");
        }
    });
});


// Lazy load
document.addEventListener("DOMContentLoaded", function () {
    const lazyElements = document.querySelectorAll(
        "figure[data-src], img[data-src], iframe[data-src], figure[src], img[src], iframe[src], figure[style*='background-image']"
    );

    const lazyLoadElements = function () {
        lazyElements.forEach(element => {
            const rect = element.getBoundingClientRect();

            // Check if the element is in the viewport
            if (rect.top < window.innerHeight && rect.bottom > 0) {
                if (element.dataset.src) {
                    // Load the image or iframe source
                    element.src = element.dataset.src;
                    delete element.dataset.src; // Optionally remove data-src attribute after loading
                } else if (element.style.backgroundImage && element.style.backgroundImage.includes('url(')) {
                    // If background-image is specified, set the style directly
                    element.style.backgroundImage = element.style.backgroundImage.replace(/url\(['"]?/, 'url("').replace(/['"]?\)/, '")');
                }
            }
        });
    };

    lazyLoadElements();
    window.addEventListener("scroll", lazyLoadElements);
});



// Load more
document.addEventListener('DOMContentLoaded', function () {
    var loadMoreButton = document.getElementById('more_posts');
    if (loadMoreButton) {
        var page = 1;
        var postsPerPage = 5;
        var postsNotIn = [];
        var loading = false;
        var container = document.getElementById('ajax-posts');
        var maxNumPages = parseInt(container.getAttribute('data-pages'), 10);

        if (loadMoreButton.dataset.type === 'video') {
            maxNumPages = maxNumPages / 4;
        }

        loadMoreButton.addEventListener('click', function () {

            loadMoreButton.textContent = myTranslations.loading ? myTranslations.loading + ' ...' : 'Loading ...';
            loadMoreButton.disabled = true;

            if (!loading) {
                loading = true;
                var data = new URLSearchParams();
                data.append('action', 'custom_load_more_posts');
                data.append('type', loadMoreButton.dataset.type);
                data.append('postsPerPage', loadMoreButton.dataset.postsPerPage);
                data.append('postsNotIn', loadMoreButton.dataset.postsNotIn);
                data.append('page', page);
                data.append('nonce', window.nonce);  // Add nonce to the data

                fetch(window.wp_ajax, { // Use the global variable for AJAX URL
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then(response => response.text())
                    .then(function (response) {
                        if (response) {
                            container.insertAdjacentHTML('beforeend', response);
                            loading = false;
                            loadMoreButton.textContent = myTranslations.show_more ? myTranslations.show_more : 'Show more';
                            loadMoreButton.disabled = false;

                            const event = new Event('ajaxContentLoaded');
                            document.dispatchEvent(event);

                            if (page === maxNumPages) {
                                loadMoreButton.style.display = 'none';
                            } else {
                                if (loadMoreButton.dataset.type !== 'video')
                                    loadMoreButton.insertAdjacentElement('afterend', container);
                            }
                            loadMoreButton.disabled = false;
                        } else {
                            loadMoreButton.style.display = 'none';
                        }
                    })
                    .catch(function (error) {
                        loading = false;
                        loadMoreButton.disabled = false;
                    });
            }

            page++;

        });
    }
});